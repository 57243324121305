<template>
  <v-container fluid>
    <PageHeaderSlot showBackButton> </PageHeaderSlot>

    <v-form ref="dataForm" @submit.prevent="save()">
      <v-card>
        <v-card-text class="px-2">
          <v-row class="ma-0">
            <v-col cols="12" sm="4" lg="3">
              <FormInput
                label="campusName"
                placeholder="campusName"
                :fieldValue.sync="formData.name"
                dense
                required
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              />
            </v-col>

            <v-col cols="12" sm="8" lg="9">
              <FormInput
                label="address"
                placeholder="address"
                :fieldValue.sync="formData.address"
                dense
                required
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              />
            </v-col>

            <v-col cols="12" sm="4" lg="3">
              <FormInput
                label="campusCode"
                placeholder="campusCode"
                :fieldValue.sync="formData.school_code"
                dense
                required
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              />
            </v-col>

            <!-- <v-col cols="12">
              <FormTextarea
                label="invoiceTerms"
                placeholder="invoiceTerms"
                :fieldValue.sync="formData.invoice_terms"
                dense
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              ></FormTextarea>
            </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>

      <!-- <v-card class="mt-4">
        <v-card-text class="px-2">
          <v-row class="ma-0">
            <v-col cols="12"> <h3>{{ $t('campusLogo') }}</h3></v-col>
            <v-col cols="12">
              <ImageUploader
                fileInputKey="upload_logo"
                :imgList.sync="logoList"
                acceptFormat="image"
                :dispatchUpdateOnChange="dispatchUpdateOnChange"
              ></ImageUploader>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card> -->

      <div class="d-flex align-center">
        <v-btn type="submit" class="primary mt-4" text depressed :loading="$store.getters.isLoading">{{ $t('save') }}</v-btn>
        <v-btn
          v-if="$validate.DataValid(id)"
          type="button"
          class="mt-4 ml-4"
          outlined
          color="error"
          text
          depressed
          :loading="$store.getters.isLoading"
          @click="handleDelete()"
        >{{ $t('delete') }}</v-btn>
      </div>
    </v-form>

    <ConfirmDialog ref="confirmDeleteDialog"/>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import Datatable from '@/components/Datatable.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import FormInput from '@/components/formField/FormInput.vue'
import FormSelect from '@/components/formField/FormSelect.vue'
// import ImageUploader from '@/components/ImageUploader.vue'
// import FormTextarea from '@/components/formField/FormTextarea.vue'

export default {
  name: 'ClassroomDetails',
  components: {
    Datatable,
    ConfirmDialog,
    FormInput,
    FormSelect,
    // ImageUploader,
    // FormTextarea,
  },
  computed: {
    id() {
      const id = parseInt(this.$route.params.id)
      if (!isNaN(id)) {
        return id
      }

      return null
    },
    dispatchUpdateOnChange() {
      if (this.$validate.DataValid(this.id)) {
        return true
      } else {
        return false
      }
    },
  },
  data: () => ({
    formData: {
      name: '',
      address: '',
      school_code: '',
      invoice_terms: '',
    },
    logoList: [],
  }),
  methods: {
    ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
    async getSchoolById() {
      try {
        const data = await this.$Fetcher.GetSchoolById(this.id)
        for (const key in this.formData) {
          if (this.$validate.DataValid(data[key])) {
            this.formData[key] = data[key]
          }
        }

        if (this.$validate.DataValid(data.logo)) {
          this.logoList.push(data.logo)
        }
      } catch {
        this.setDialogMessage({
          message: 'noData',
          returnLink: { name: 'SchoolList' },
        })
        this.setShowDialog(true)
      } finally {
        this.$store.dispatch('toggleLoadingPage', false)
      }
    },

    async save() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: true,
          redirect: '',
        })
        return
      }

      this.$store.dispatch('setLoading', true)

      const valid = this.$refs['dataForm'].validate()
      if (!valid) {
        this.$store.dispatch('setLoading', false)
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'required',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      let payload = {
        name: this.formData.name,
        address: this.formData.address,
        logo: this.$validate.DataValid(this.logoList) ? this.logoList[0] : '',
        school_code: this.formData.school_code,
        invoice_terms: this.formData.invoice_terms,
      }

      if (this.$validate.DataValid(this.id)) {
        // edit
        try {
          await this.$Fetcher.UpdateSchool(this.id, payload)
          this.$store.dispatch('setDataIsUpdated', false)
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'message.saveSuccess',
            type: 'success',
            refresh: true,
            redirect: '',
          })
        } catch (err) {
          this.setDialogMessage({
            title: 'message.saveFail',
            message: err,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      } else {
        // create
        try {
          await this.$Fetcher.NewSchool(payload)
          this.$store.dispatch('setDataIsUpdated', false)
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'message.createSuccess',
            type: 'success',
            refresh: false,
            redirect: 'SchoolList',
          })
        } catch (err) {
          this.setDialogMessage({
            title: 'message.createFail',
            message: err,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      }
    },

    async handleDelete() {
      const confirm = await this.$refs.confirmDeleteDialog.show()
      if (confirm) {
        this.$store.dispatch('setLoading', true)

        try {
          await this.$Fetcher.DeleteSchool(this.id)
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: 'message.deleteSuccess',
            type: 'success',
            refresh: false,
            redirect: 'SchoolList',
          })
        } catch (err) {
          this.setDialogMessage({
            title: 'message.deleteFail',
            message: err,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    if (this.$validate.DataValid(this.id)) {
      // edit
      this.getSchoolById()
    } else {
      // new
      this.$store.dispatch('toggleLoadingPage', false)
    }
  },

  // ------ navigation guard ------
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoading) {
      this.$store.dispatch('toggleAlertMessage', {
        show: true,
        message: 'processing',
        type: 'error',
        refresh: false,
        redirect: '',
      })
      next(false)
    } else if (this.$store.getters.dataIsUpdated) {
      this.setLeaveDialogLink({ name: to.name })
      this.setShowLeaveDialog(true)
      next(false)
    } else {
      next()
    }
  },
}
</script>
